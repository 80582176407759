exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-article-2-js": () => import("./../../../src/pages/article2.js" /* webpackChunkName: "component---src-pages-article-2-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-hub-js": () => import("./../../../src/pages/hub.js" /* webpackChunkName: "component---src-pages-hub-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-php-programming-uae-js": () => import("./../../../src/pages/php-programming-uae.js" /* webpackChunkName: "component---src-pages-php-programming-uae-js" */),
  "component---src-pages-schedule-a-call-js": () => import("./../../../src/pages/schedule-a-call.js" /* webpackChunkName: "component---src-pages-schedule-a-call-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-web-development-uae-js": () => import("./../../../src/pages/web-development-uae.js" /* webpackChunkName: "component---src-pages-web-development-uae-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-worksheet-js": () => import("./../../../src/pages/worksheet.js" /* webpackChunkName: "component---src-pages-worksheet-js" */),
  "component---src-templates-demo-page-js": () => import("./../../../src/templates/demo-page.js" /* webpackChunkName: "component---src-templates-demo-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single-event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-guide-js": () => import("./../../../src/templates/single-guide.js" /* webpackChunkName: "component---src-templates-single-guide-js" */),
  "component---src-templates-single-industries-js": () => import("./../../../src/templates/single-industries.js" /* webpackChunkName: "component---src-templates-single-industries-js" */),
  "component---src-templates-single-platforms-js": () => import("./../../../src/templates/single-platforms.js" /* webpackChunkName: "component---src-templates-single-platforms-js" */),
  "component---src-templates-single-process-js": () => import("./../../../src/templates/single-process.js" /* webpackChunkName: "component---src-templates-single-process-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-single-work-js": () => import("./../../../src/templates/single-work.js" /* webpackChunkName: "component---src-templates-single-work-js" */),
  "component---src-templates-single-worksheet-js": () => import("./../../../src/templates/single-worksheet.js" /* webpackChunkName: "component---src-templates-single-worksheet-js" */)
}

